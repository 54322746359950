<template>
<div>
  <table style="width: 100%">
      <tr>
          <th colspan="5">
            <span class="text-h6 text-primary-darken-2">
              <b>Betreuungszeiten</b>
            </span>
          </th>
      </tr>
      <tr>
          <th>Montag</th>
          <th>Dienstag</th>
          <th>Mittwoch</th>
          <th>Donnerstag</th>
          <th>Freitag</th>
      </tr>
      <tr>
         <template v-for="(item, key) in betreuungszeiten">
            <td v-if="key !== 'samstag' && key !== 'sonntag'" :key="key">
              <div align="center">
                <span v-if="item !== null && (item.von !== null || item.bis !== null)">
                  <span v-if="item.von !== null">{{ cutSeconds(item.von) }}</span> -
                  <span v-if="item.bis !== null">{{ cutSeconds(item.bis) }}</span>
                </span>
                <Tooltip top v-else>
                    <v-icon class="pointer">mdi-minus</v-icon>
                  <template #content>
                    <span>Keine Angabe</span>
                  </template>
                </Tooltip>
              </div>
            </td>
         </template>
      </tr>
      <tr>
        <template v-for="(item, key) in betreuungszeiten">
          <td v-if="key !== 'samstag' && key !== 'sonntag'" :key="key">
            <div align="center">
              <Tooltip top v-if="item !== null && item.mittagessen">
                  <v-icon class="pointer">mdi-silverware</v-icon>
                <template #content>
                  <span>Mittagessen gewünscht</span>
                </template>
              </Tooltip>
              <Tooltip top v-else>
                  <v-icon class="pointer">mdi-minus</v-icon>
                <template #content>
                  <span>Kein Mittagessen gewünscht</span>
                </template>
              </Tooltip>
            </div>
          </td>
        </template>
      </tr>
      <tr>
        <template v-for="(item, key) in betreuungszeiten">
          <td v-if="key !== 'samstag' && key !== 'sonntag'" :key="key">
            <div align="center">
              <Tooltip top v-if="item !== null && item.fruehdienst">
                  <v-icon class="pointer">mdi-alpha-f-circle</v-icon>
                <template #content>
                  <span>Frühdienst</span>
                </template>
              </Tooltip>
              <Tooltip top v-else>
                  <v-icon class="pointer">mdi-minus</v-icon>
                <template #content>
                  <span>Kein Frühdienst</span>
                </template>
              </Tooltip>
            </div>
          </td>
        </template>
      </tr>
  </table>
</div>
</template>
<script>
import Tooltip from '@s/views/general_components/components/Tooltip'
import { cutSeconds } from '@s/util/helper'

export default {
    name: "betreuungszeiten-table",
    components: {
      Tooltip
    },
    props: {
      betreuungszeiten: {
        type: Object,
        required: true
      }
    },
    methods: {
      cutSeconds,
    }
}
</script>

<style lang="stylus" scoped>
    i
      font-weight: bold

    td, th
      border: 1px solid #dddddd
      text-align: left
      padding: 8px

    tr:nth-child(even)
      background-color: #dddddd

</style>
